body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  body {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  body::-webkit-scrollbar {
    display: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

.card-dash {
    width:15rem;
    margin:0.5rem
}

.center {
    margin: auto;
    width:75%;
    padding:10px;
}

.gauge-holder {
    width:90%;
    margin:auto;
    margin-top: 3%;
}

.gauge {
    width:100%;
    max-width:250px;
    color: #009578;
    font-size: 150%;
}

.gauge-body {
    width:100%;
    height:0;
    padding-bottom: 50%;
    background: #b4c0be;
    position: relative;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    overflow: hidden !important;
}

.gauge-fill {
    position: absolute;
    top:100%;
    left:0;
    width: inherit;
    height:100%;
    background:#009578;
    transform-origin:center top;
    transform: rotate(0.25turn);
    transition: transform 0.2s ease-out;
}

.gauge-cover {
    width:75%;
    height:150%;
    background:white;
    border-radius: 50%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    
    /* Text */
    display:flex;
    align-items:center;
    justify-content: center;
    padding-bottom: 25%;
    box-sizing: border-box;
}

.elapsedTime {
    text-align: center;
    font-size: 2.5rem;
    color: black;
}


#navBarLogo {
   
    padding-left: 10px;
    padding-right:10px;
}



.navbar {
    background-color:rgb(247, 247, 247) ;
    color: black ;
    width:100vw;
}

.menuBar {
    text-align: center;
    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
    margin-bottom: 0;
    margin-top:0;
    color: black;
}

.nav-link-container a {
    float: left;
    display:block;
    color: black ;
    text-align: center;
    padding: 1rem 1rem;
    text-decoration: none;
}

.nav-link-container a:hover {
    background-color: #ddd;
}



/* Menu Responsive 
@media screen and (max-width:639px) {
    .nav-link-container a {display: none;}
    .nav-link-container a.icon {
        float:right;
        display:block;
    }
    .nav-link-container.responsive {position: relative;}
    .nav-link-container.responsive a.icon {
        display:block;
        position:absolute;
        right:0;
        top:0;
    }
    .nav-link-container.responsive a {
        float: none;
        display: block;
        text-align: right;
        
    }
    .nav-link-container.responsive a.menutabmove {
        display: absolute;
        translate: 0rem 0rem;
        background-color: rgb(247,247,247);
    }
}

*/







/* Mobile grid */
.grid-items {
    display: grid;
    justify-items: center;
    grid-template-columns:100%;
    grid-column-gap:0;
    grid-row-gap:0.5rem
}

/* Tablet Grid */
@media only screen and (min-width:640px) {
    .grid-items {
        grid-template-columns: 47.5% 47.5%;
        grid-column-gap: 5%;
        grid-row-gap: 0.5rem;
    }
}

/* Laptop Grid */
@media only screen and (min-width:981px) {
    .grid-items {
        grid-template-columns: 22.5% 22.5% 22.5% 22.5%;
        grid-column-gap: 2%;
        grid-row-gap:0.5rem

    }
}

@keyframes fadeInUp {
    0% {opacity:0;
        translate: 0% 100%;}
    100% {opacity:1;
    translate: 0% 0%;}
}

.telemetry {
    animation: fadeInUp 1s;
}


.timer-button-right {
   
    margin-left: 0.3rem;
}

.timer-button-container {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}


progress[value] {
    -webkit-appearance: none;
    appearance: none;
    margin-bottom: 0.4rem;
    border-radius: 2px;
    width:100%;
    height:1.5rem;
}

.homepage-dash {
    max-width:40em;
    margin: auto;
}

.configure-dash {
    max-width: 45rem;
    margin:auto;
}

progress[value]::-webkit-progress-bar {
    background-color: #b4c0be;
}

progress[value]::-webkit-progress-value {
    background-color: #009578;
}

.home-page-card {
    display:flex;
    margin:auto;
    width:90%;
    padding:2rem;
    height:auto;
    margin-top: 5%;
}

.home-page-card--cars {

}

.home-page-card--title {
    text-align: center;
    margin: 1rem;
}

.car-summary {
    width:95%;
    margin:auto;
  }

.car-summary-title {

}

.form-signin {
    display: flex;
    height:74.7vh;
    justify-content: center;
    align-items:center;
  }

.signin-form {
    align-self: center;
    width:400px;
    max-width: 75%;
  }

.hidden {
    display:none;
}

.car-summary-vis {
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction: row;
}

.summary-card {
    width: 100% !important;
    margin: auto !important;
    height:fit-content !important;
    padding: 0 !important;
    
}

.summary-link, .summary-link:hover {
    color: var(--bs-body-color);
    text-decoration: none;
    text-align: center;
}

.btn-block {
    width:100%
}

.summary-card-label {
    display: flex;
    justify-content: space-between;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh
}

.upgrade-screen {
    position: absolute;
    box-shadow: inset 0 0 0 3000px rgba(150,150,150,0.192);
    filter:blur(10px);
    border-radius: 10px;
}

.upgrade-background {
    height: 5rem;
    width: 100%;
    padding: none;
    background: linear-gradient(45deg, rgb(127, 48, 146) 0%, rgb(31, 31, 90) 0%, rgba(0,212,255,1) 100%);

}